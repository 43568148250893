import React from "react"
import Spinner from "../components/spinner";
import "./results.scss";
import * as graphql from "../apollo/graphql";
import { useAppContext } from "../context";
import Media from '../components/media'
import { FaInstagram, FaClock, FaRegClock, FaSearch } from "react-icons/fa";
import LoadingButton from "../components/loading-button";
import { format, parseISO } from "date-fns";

const Submission = ({ submission }) => {
  const app = useAppContext()
  const [disableSubmission] = graphql.useSetSubmissionStatusMutation({
    refetchQueries: [{ query: graphql.SubmissionsDocument }]
  });

  const takenAt = format(parseISO(submission.taken_at), "PP")
  const addedAt = format(parseISO(submission.created_at), "PP")
  return (
    <div className="card box-shadow">
      <div className="card-img-top">
        <a href={submission.url} target="_blank" rel="noopener noreferrer">
          <Media image_url={submission.image_url} video_url={submission.video_url} />
        </a>
      </div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="text-truncate">
            <a className="mr-2" href={submission.url} target="_blank" rel="noopener noreferrer" title="View on Instagram"><FaInstagram /></a>
            <a href={`https://www.instagram.com/${submission.username}/`} target="_blank" rel="noopener noreferrer" title="View user's profile on Instagram">@{submission.username}</a>
          </div>
        </li>
        <li className="list-group-item">
          <div className="text-truncate d-flex align-items-center" title={`Created on ${takenAt}`}>
            <FaRegClock className="mr-2" /> {takenAt}
          </div>
          <div className="text-truncate d-flex align-items-center" title={`Added to BuckUBack on ${addedAt}`}>
            <FaSearch className="mr-2" /> {addedAt}
          </div>
        </li>
        <li className="list-group-item">
          <span className="extra-small">{submission.id}</span>
        </li>
        {app.user?.role === "editor" && <li className="list-group-item d-flex justify-content-between align-items-center">
          <button className={`btn btn-sm ${submission.is_active ? "btn-secondary" : "btn-success"}`} onClick={() =>
            disableSubmission({
              variables: {
                submission_id: submission.id,
                active: !submission.is_active
              }
            })
          }>{submission.is_active ? "Disable" : "Enable"}</button>
        </li>}
      </ul>
    </div>
  );
};

const ScrapeLatestButton = () => {
  const download = () =>
    fetch(`/api/scrape`).then(async response => {
      const data = await response.json()

      if (data.downloadUrl) {
        window.open(data.downloadUrl, "_self");
      }
    }).catch(console.error)

  return (
    <div className="download-poll mb-4">
      <LoadingButton
        loadingChildren="Scraping Submissions"
        callbackPromise={download}
      >
        Scrape Latest Submissions
      </LoadingButton>
    </div>
  );
};

const EditorControls = () => {
  const app = useAppContext();

  return (
    <div>
      <ScrapeLatestButton />
    </div>
  );
};

export default () => {
  const app = useAppContext();

  const { data, loading, error } = graphql.useSubmissionsQuery({
    pollInterval: app.user?.role == "editor" ? 2000 : undefined
  });

  if (loading)
    return (
      <div className="jumbotron">
        <div className="container">
          <Spinner />
        </div>
      </div>
    );

  return (
    <div className="submission">
      <div className="jumbotron">
        <div className="container">
          <h1 className="jumbotron-heading">Submissions</h1>
          <p className="lead text-muted">Latest 500 submissions.</p>
          {/* {app.user && app.user.role == "editor" && <EditorControls />} */}
        </div>
      </div>
      <div className="submissions-body content-inner bg-light">
        <div className="container submissions-small">
          {data.submissions.map(submission => {
            return <Submission key={submission.id} submission={submission} />;
          })}
        </div>
      </div>
    </div>
  );
};
