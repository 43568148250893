import React from "react"
import Submissions from "../containers/submissions"
import Layout from "../containers/layout"
import Private from "../components/private"

export default () => (
  <Private>
    <Layout>
      <Submissions />
    </Layout>
  </Private>
)
