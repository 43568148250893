import React, { useState, useEffect } from "react";

export default function LoadingButton({
  children,
  loadingChildren,
  callbackPromise
}) {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      callbackPromise().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => setLoading(true);

  return (
    <button
      className="btn btn-primary"
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? (
        <span
          className="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        />
      ) : (
          undefined
        )}
      {!isLoading ? children : loadingChildren}
    </button>
  );
}
